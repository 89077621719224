import React from "react";
// import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const EventsPage: React.FC = () => (
    <Layout>
        <SEO title="Events" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Events</h1>

                <p>
                    If you're in the San Francisco Bay Area, please join the{" "}
                    <a href="https://codeselfstudy.com/">Code Self Study</a>{" "}
                    community. We host over 100 meetups per year. More events
                    are coming soon.
                </p>
            </div>
        </section>
    </Layout>
);

export default EventsPage;
